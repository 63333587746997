<template>
  <header ref="header" :class="headerFixed ? 'isFixed' : ''">
    <div class="fontTest">
      <img src="https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/LOGO.png" alt="" />
      <div class="head_title_box">
        <span class="head_title" id="head_title" @click="toIndex" to="/index">苏州快活猿科技有限公司</span>
      </div>
    </div>
    <div class="rightNavBox">
      <ul class="rightNav">
        <li class="testLi" @click.stop="handle">
          <span @click="toIndex">首页</span>
          <div class="box" @click.stop>
            <div class="content">
              <p :key="item.id" v-for="item in navigatList" @click="scrollToViewTest(item.id)">
                {{ item.title }}
              </p>
            </div>
          </div>
        </li>
        <li class="testLi" @click.stop="handle">
          <span @click="toUs">关于我们</span>
          <div class="box" @click.stop>
            <div class="content">
              <p :key="item.id" v-for="item in usNavList" @click="scrollToUs(item.id)">{{ item.title }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="rightNav_mobile" tabindex="0" ref="rightNav_mobile" @click="handleMobileNavClick" @blur="handleMobileNavBlur">
      <div class="img_box">
        <img src="../../assets/icon/meau.svg" alt="" />
      </div>
      <div class="sidebar">
        <div class="sidebar_item" tabindex="1" @click.stop="handleSiderBarItemClick" @blur.stop="handleSiderBarItemBlur">
          <span style="width: 72px">首页</span>
          <div class="sidebar_item_second_box">
            <div class="sidebar_item_second">
              <p :key="item.id" v-for="item in navigatList" @click.stop="scrollToViewTest(item.id)">{{ item.title }}</p>
            </div>
          </div>
        </div>

        <div class="sidebar_item" tabindex="2" @click.stop="handleSiderBarItemClick" @blur.stop="handleSiderBarItemBlur">
          <span>关于我们</span>
          <div class="sidebar_item_second_box">
            <div class="sidebar_item_second">
              <p :key="item.id" v-for="item in usNavList" @click="scrollToUs(item.id)">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { eventBus } from '../../main.js'
export default {
  name: 'HeaderView',
  data() {
    return {
      offsetTop: 0,
      offsetHeight: 0,
      headerFixed: 0,
      navigatList: [
        { title: '了解我们', id: 1 },
        { title: '信息技术服务', id: 2 },
        { title: '行业解决方案', id: 3 },
        { title: '优势', id: 4 }
      ],
      usNavList: [
        { title: '了解我们', id: 5 },
        { title: '荣誉资质', id: 6 },
        { title: '精彩记忆', id: 7 },
        { title: '联系我们', id: 8 }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 获取吸顶元素的dom
      let header = this.$refs.header
      // 吸顶元素到top的距离
      this.offsetTop = header.offsetTop
      // 元素自身的高度
      this.offsetHeight = header.offsetHeight
      // 监听滚动条
      window.addEventListener('scroll', this.handleScroll)
    })
    //   this.scrollToViewTesthandleMobileNavBlur()
    // this.scrollToUs()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleMobileNavClick() {
      this.$refs.rightNav_mobile.classList.toggle('active')
    },
    handleMobileNavBlur(e) {
      if (!this.$refs.rightNav_mobile.contains(e.relatedTarget)) {
        // 如果焦点移动到了不是rightNav_mobile的子元素上，则执行 blur 操作
        this.$refs.rightNav_mobile.className = 'rightNav_mobile'
      }
    },
    handleSiderBarItemClick(e) {
      // this.$refs.rightNav_mobile.className = 'rightNav_mobile active'
      e.target.classList.toggle('active')
    },
    handleSiderBarItemBlur(e) {
      e.target.classList.remove('active')
      if (!this.$refs.rightNav_mobile.contains(e.relatedTarget)) {
        // 如果焦点移动到了不是rightNav_mobile的子元素上，则执行 blur 操作
        this.$refs.rightNav_mobile.classList.remove('active')
      }
    },
    myLog() {
      console.log('-------')
      debugger
    },
    handleScroll() {
      // 得到页面滚动的距离，兼容写法
      let scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
      // 判断页面滚动的距离是否大于吸顶元素的位置
      this.headerFixed = scrollTop > this.offsetTop - this.offsetHeight
    },
    handle(e) {
      document.querySelectorAll('.testLi').forEach((item) => {
        item.className = 'testLi'
      })
      e.target.className = 'testLi active'
    },
    hoverShow(e) {
      document.querySelectorAll('.head_right_tip').forEach((item) => {
        item.className = 'head_right_tip'
      })
      e.target.className = 'head_right_tip active'
    },

    scrollToViewTest(id) {
      console.log('route--->', this.$route.path)
      if (this.$route.path !== '/home') {
        this.$router.push('/home').then(() => {
          eventBus.$emit('scrollToSection', id)
        })
      } else {
        eventBus.$emit('scrollToSection', id)
      }
    },
    scrollToUs(id) {
      if (this.$route.path !== '/aboutus') {
        this.$router.push('/aboutus').then(() => {
          eventBus.$emit('scrollToSection_02', id)
        })
      } else {
        eventBus.$emit('scrollToSection_02', id)
      }
    },
    toUs() {
      this.$router.push('/aboutus')
    },
    toIndex() {
      this.$router.push('/home')
    }
  }
}
</script>
<style>
@media screen and (max-width: 1528px) {
  #head_title {
    font-size: 2.3rem !important;
  }
}
</style>
<style lang="less" src="./headerIndex.less" scoped></style>
