<template>
  <div class="container">
    <!-- 公司简介 -->
    <div class="banner01" id="section-5">
      <div class="banner01_text">
        <img src="" alt="" class="commonIcon" />
        <p class="banner01_text_01">了解快活猿</p>
        <p class="banner01_text_02">KNOW ABOUT US</p>
        <p class="banner01_text_02">值得信赖的数字化升级合作伙伴</p>
      </div>
      <div class="banner01_profile">
        <p>
          苏州快活猿科技有限公司是一家现代化互联网高科技公司，公司以AI算法、大数据分析、物联网平台为核心能力，为智慧社区、智慧园区、智慧商业、智慧市政、互联网等细分场景提供全生态服务，为产业提供丰富的人工智能算法与基建平台，赋能百业完成智能化转型升级。
        </p>
        <p>
          公司主要业务和能力为系统应用、移动端应用、工业互联网、算法A1、物联网。公司深耕行业多年，形成了具备智力裂变能力的开发者生态，以高效、灵活、优质的服务满足企业需求，与恒力、恒瑞、亨通、迈瑞、白湖监狱等单位为长期合作伙伴关系。
          公司期待通过不断努力，锐意进取，让科技真正创造价值、普惠大众。
        </p>
      </div>
    </div>
    <!-- 资质荣誉 -->
    <div class="qualificationHonor" id="section-6">
      <div class="qualification_text">
        <div class="animate">
          <p class="qualification_text_01">资质荣誉</p>
        </div>
        <div class="animate">
          <p class="qualification_text_02">QUALIFICATION HONOR</p>
        </div>
      </div>

      <!-- ------滑动卡片------ -->

      <div class="swiper-container" id="swiper_1">
        <div class="swiper-wrapper">
          <div class="swiper-slide" :key="index" v-for="(item, index) in swiperList">
            <div class="card">
              <!-- <div class="card_image"> -->
              <el-image :src="item.imgpic" alt="" fit="cover" :preview-src-list="[item.imgpic]" class="card_img" />
              <!-- </div> -->
              <div class="name">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <!-- 公司文化 -->
    <div class="banner2">
      <div class="item_01">
        <div class="item_01_box">
          <img class="image" src="https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/shiming.svg" alt="" />
          <div class="animate">
            <div class="watchword">企业使命</div>
          </div>
          <div class="animate">
            <p>OUR MISSION</p>
          </div>
          <div class="animate">
            <p>为客户创造价值，赋能企业创新成长，做客户信赖的合作伙伴</p>
          </div>
        </div>
      </div>
      <div class="item_02 animate">
        <img class="image" src="https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/teamwork-3213924_1280.jpg" alt="" />
      </div>
      <div class="item_01">
        <div class="item_01_box">
          <img class="image" src="https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/jinrongzhanlue.svg" alt="" />
          <div class="animate">
            <div class="watchword">发展战略</div>
          </div>
          <div class="animate">
            <p>DEVELOPMENT STRATEGY</p>
          </div>
          <div class="animate">
            <p>实现公司、客户与员工的价值共赢，凭借锲而不舍的精益管理，不断提升公司综合实力，成为中国领先的科技公司</p>
          </div>
        </div>
      </div>
      <div class="item_02 animate_left">
        <img class="image" src="https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/laptop-3196481_1280.jpg" alt="" />
      </div>
      <div class="item_01">
        <div class="item_01_box">
          <img class="image" src="https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/icon/hangyeqianjing.svg" alt="" />
          <div class="animate">
            <div class="watchword">企业愿景</div>
          </div>
          <div class="animate">
            <p>CORPORATE VISION</p>
          </div>
          <div class="animate">
            <p>发掘机遇，创造价值 打造具有核心竞争力的现代综合科技公司</p>
          </div>
        </div>
      </div>
      <div class="item_02 animate_right">
        <img class="image" src="https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/background-2846206_1280.jpg" alt="" />
      </div>
    </div>

    <!-- 精彩记忆 -->
    <div class="photowall" id="section-7">
      <div class="photowallContent">
        <div class="animate">
          <div class="title">精彩瞬间</div>
        </div>
        <div class="tab">
          <div class="tab_item" @click="changePhone">团 建</div>
          <div class="tab_item" @click="changePhone_02">年 会</div>
        </div>
        <div class="gridContent">
          <div class="gridItem" :key="index" v-for="(item, index) in gridPicList" :style="{ gridArea: `pic${index + 1}` }">
            <img :src="gridPicList[index].frontUrl" style="width: 100%; height: 100%; object-fit: cover" alt="" />
            <img :src="gridPicList[index].backUrl" style="width: 100%; height: 100%; object-fit: cover" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="banner3" id="section-8">
      <div class="banner3_text">
        <div class="animate">
          <p class="banner3_text_01">联系我们</p>
        </div>
        <div class="animate">
          <p class="banner3_text_02">如需商业合作、投递简历，请填写下面信息，欢迎您的咨询</p>
        </div>
      </div>
    </div>
    <!-- 填写信息 -->
    <div class="emailBox">
      <div class="form_box">
        <h2>联系我们</h2>
        <p>欢迎咨询，完善信息后我们会第一时间联系您</p>
        <div style="display: flex; flex-direction: column; align-items: center">
          <el-form class="gridEmailContent" ref="formData" :rules="rules" :model="formData">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item class="gridEmailItem" prop="name">
                  <el-input name="name" clearable v-model="formData.name" placeholder="姓名：" /></el-form-item
              ></el-col>
              <el-col :span="12"
                ><el-form-item class="gridEmailItem">
                  <el-input name="job" clearable v-model="formData.job" placeholder="职位：" /></el-form-item
              ></el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12"
                ><el-form-item class="gridEmailItem" prop="mobile">
                  <el-input name="phone" clearable v-model="formData.mobile" placeholder="联系电话：" /></el-form-item
              ></el-col>
              <el-col :span="12">
                <el-form-item class="gridEmailItem" prop="email">
                  <el-input name="reply_email" clearable v-model="formData.email" placeholder="联系邮箱·：" /></el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="24"
                ><el-form-item class="gridEmailItem">
                  <el-input name="address" clearable v-model="formData.companyAddress" placeholder="公司所在地：" /></el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="24"
                ><el-form-item class="gridEmailItem">
                  <el-input name="companyName" clearable v-model="formData.companyName" placeholder="企业名称：" /></el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="12"
                ><el-form-item class="gridEmailItem">
                  <el-select v-model="formData.industry" clearable placeholder="请选择所属行业">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label">
                    </el-option> </el-select></el-form-item
              ></el-col>
              <el-col :span="12"
                ><el-form-item class="gridEmailItem">
                  <el-select v-model="formData.range" clearable placeholder="请选择项目预算范围">
                    <el-option v-for="item in rangeOptions" :key="item.value" :label="item.label" :value="item.label">
                    </el-option> </el-select></el-form-item
              ></el-col>
            </el-row>
            <el-row class="requirement">
              <el-col :span="24">
                <el-form-item class="gridEmailItem">
                  <el-input
                    type="textarea"
                    clearable
                    :rows="4"
                    name="message"
                    v-model="formData.desc"
                    placeholder="需求描述："
                  ></el-input></el-form-item
              ></el-col>
            </el-row>
            <el-row class="uploadModule">
              <el-col :span="24">
                <div class="gridEmailItem upload" style="grid-area: file9; width: 100%">
                  <el-upload
                    ref="upfile"
                    style="text-align: center"
                    :auto-upload="false"
                    :on-change="handleChange"
                    :file-list="fileList"
                    :accept="'application/pdf,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                    action="#"
                    multiple
                    :limit="3"
                    :max-size="5120"
                    :on-exceed="handleExceed"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">
                      最多上传3个文件，且只能上传PDF、文本、Word类型文件，总大小不超过10MB
                    </div>
                  </el-upload>
                </div>
              </el-col>
            </el-row>
          </el-form>
          <div class="consult_box">
            <div class="consult_box_text">咨询热线：0512-65999885 <br />咨询邮箱：marketing@kuaihuoyuan.cn</div>
            <el-button type="primary" @click="sendMail">完成并发送</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser'
import Swiper from 'swiper'
import { eventBus } from '../../main.js'
import { sendEmail } from '../../api/email.js'
import { uploadFileList } from '../../api/httpRequest.js'
import 'swiper/css/swiper.min.css'

export default {
  name: 'aboutUs',
  data() {
    //验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      //验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (regEmail.test(value)) {
        //合法的邮箱
        return cb()
      }
      //邮箱不合法
      cb(new Error('请输入合法的邮箱！'))
    }
    //验证手机号的规则
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        //合法的手机号
        return cb()
      }
      //手机号不合法
      cb(new Error('请输入合法的手机号！'))
    }
    return {
      formData: {
        name: '',
        mobile: '',
        job: '',
        email: '',
        companyAddress: '',
        companyName: '',
        industry: '',
        range: ''
      },
      rules: {
        name: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        email: [
          { message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      multiFile: [],
      fileList: [],
      swiperList: [
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper11.jpg',
          title: 'plc通信信号处理与分析软件',
          content: '2022年09月30日，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper10.jpg',
          title: 'plc通信数据发送与接收处理系统',
          content: '2022年10月30日，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper06.jpg',
          title: '上位机交互式操控系统',
          content: '2023年02月10日，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper12.jpg',
          title: '互联网数据安全监测系统',
          content: '2022年10月18日，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper04.jpg',
          title: '人工智能开发维护系统',
          content: '2023年01月15日，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper01.jpg',
          title: '基于GMP的药品先进工艺加工管控系统',
          content: '2022年06月01日，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper13.jpg',
          title: '基于GMP的药品生产全周期管理系统',
          content: '2022年12月15日，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper02.jpg',
          title: '基于GMP的装药生产线自动化控制系统',
          content: '2022年7月18日，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper08.jpg',
          title: '物联网远程监测与调控系统',
          content: '2023年04月25日，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper07.jpg',
          title: '物联网集成式控制系统',
          content: '2023年03月，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper09.jpg',
          title: '药品生产GMP成分智能分析系统',
          content: '2022年9月23日，获得证书'
        },
        {
          imgpic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/swiper05.jpg',
          title: '软件开发应用管理系统',
          content: '2023年1月30日，获得软件产品证书'
        }
      ],
      gridPicList: [
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic038.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic06.jpeg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic01.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic014.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic02.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic015.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic03.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic016.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic032.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic017.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic05.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic018.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/143.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic019.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic031.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic020.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic027.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic021.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic035.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic022.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic034.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic023.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic033.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic024.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic012.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic025.jpg'
        },
        {
          frontUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic013.jpg',
          backUrl: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pic026.jpg'
        }
      ],

      options: [
        { value: '1', label: '高科技' },
        { value: '2', label: 'IT互联网' },
        { value: '3', label: '制造能源' },
        { value: '4', label: '汽车' },
        { value: '5', label: '医疗健康' },
        { value: '6', label: '金融保险' },
        { value: '7', label: '快消零售' },
        { value: '8', label: '游戏文娱' },
        { value: '9', label: '地产建筑' },
        { value: '10', label: '公共管理' },
        { value: '11', label: '民航物流' },
        { value: '12', label: '电信' },
        { value: '13', label: '教育培训' },
        { value: '14', label: '其他' }
      ],

      rangeOptions: [
        { value: '1', label: '1w以下' },
        { value: '2', label: '1-5w' },
        { value: '3', label: '5-10w' },
        { value: '4', label: '10-20w' },
        { value: '5', label: '20-50w' },
        { value: '6', label: '50-80w' },
        { value: '7', label: '80-100w' },
        { value: '8', label: '100w以上' }
      ]
    }
  },
  created() {
    // 点击上方导航栏移动到相应位置
    eventBus.$on('scrollToSection_02', (id) => {
      const targetElementId = `section-${id}`
      const targetElement = document.getElementById(targetElementId)
      if (targetElement) {
        if (window.innerWidth > 550) {
          console.log('window.innerWidth---', window.innerWidth)
          const offset = 90 // 设置滚动偏移量
          const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          })
        } else {
          console.log('mobile.innerWidth---', window.innerWidth)
          const mobile_offset = 60
          const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - mobile_offset
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          })
        }

        // targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    })
  },
  mounted() {
    // 定义轮播图
    var swiper1 = new Swiper('#swiper_1', {
      direction: 'horizontal', // 水平切换选项
      loop: true, // 循环模式选项   现在的问题在轮播i图上，网页端挺好，就是下方的小点和内容重叠，移动端看着就不对劲

      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
        paginationProgressRender: function (swiper, progressbarClass) {
          return '<span class="' + progressbarClass + '"></span>'
        }
      },
      // autoplay: true,
      initialSlide: 3,
      effect: 'coverflow',
      slidesPerView: 3,
      centeredSlides: true,
      coverflowEffect: {
        slideShadows: false
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      // 如果需要滚动条
      scrollbar: {
        el: '.swiper-scrollbar'
      }
    })

    // 进入视口动画  上下移动
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // 如果 intersectionRatio 为 0，则目标在视野外，
          // 不需要做任何事情。
          if (entry.isIntersecting) {
            // console.log('中--在视口内')
            // console.log('entry--', entry)
            // console.log(' entry.target---', entry.target)
            // console.log(' entry.target.children[0]---', entry.target.children[0])
            // console.log(' entry.target.children[0].classList---', entry.target.children[0].classList)
            entry.target.children[0].classList.add('animateActive')
          } else {
            console.log('中--不在视口内')
            entry.target.children[0].classList.remove('animateActive')
          }
        })
      }
      // { threshold: 0.1 }
    )
    const animateItems = document.querySelectorAll('.animate') //获取所有animate
    animateItems.forEach((animateItem) => {
      intersectionObserver.observe(animateItem)
    })
    // 进入视口动画  从左边飞入
    const interObserver_left = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // 如果 intersectionRatio 为 0，则目标在视野外，
          // 不需要做任何事情。
          if (entry.isIntersecting) {
            console.log('左--在视口内')
            entry.target.children[0].classList.add('animateActive_left')
          } else {
            console.log('左--不在视口内')
            entry.target.children[0].classList.remove('animateActive_left')
          }
        })
      },
      { threshold: 0.1 }
    )
    const animateItems_left = document.querySelectorAll('.animate_left') //获取所有animate
    animateItems_left.forEach((animateItem) => {
      interObserver_left.observe(animateItem)
    })
    // 进入视口动画  从右边飞入
    const interObserver_right = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // 如果 intersectionRatio 为 0，则目标在视野外，
          // 不需要做任何事情。
          if (entry.isIntersecting) {
            console.log('右--在视口内')
            entry.target.children[0].classList.add('animateActive_right')
          } else {
            console.log('右--不在视口内')
            entry.target.children[0].classList.remove('animateActive_right')
          }
        })
      },
      { threshold: 0.1 }
    )
    const animateItems_right = document.querySelectorAll('.animate_right') //获取所有animate
    animateItems_right.forEach((animateItem) => {
      interObserver_right.observe(animateItem)
    })
  },
  methods: {
    to(index) {
      this.swiper.slideTo(index)
    },
    choosefile(e) {
      console.log('e', e)
    },
    sendMail(e) {
      e.preventDefault()

      let formData = new FormData()

      this.multiFile.forEach((el) => {
        console.log('el==>', el)
        formData.append('multiFile', el)
      })

      console.log(Object.keys(this.formData))
      for (const key in this.formData) {
        formData.append(key, this.formData[key])
      }
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          const res = await sendEmail(formData)
          console.log('res==>', res)
          if (res && res.code === 200) {
            this.$message({
              message: '提交成功！',
              type: 'success'
            })
          } else {
            this.$message.error('出错了')
          }
          // 清空表单数据和文件列表
          this.formData = {
            name: '',
            mobile: '',
            job: '',
            email: '',
            companyAddress: '',
            companyName: '',
            industry: '',
            range: ''
          }
          this.fileList = []
          this.multiFile = []
          console.log('multiFile', this.multiFile)
        } else {
          // this.$message({
          //   message: '请按要求填写信息！',
          //   type: 'warning'
          // })
        }
      })
    },

    getSelectNode() {
      var selectNode = document.getElementById('selectIndustry')
      console.log(selectNode.value) //拿到下拉框选中的值
      console.log(selectNode.selectedIndex) //拿到选中值的索引
    },
    // 文件上传

    handleExceed(files, fileList) {
      //文件限制数量
      this.$message.warning(`最多选择3个文件`)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeUpload(file) {
      const isAllowedType = [
        'application/pdf',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ].includes(file.type)
      const isSizeValid = file.size / 1024 <= 10240 // 1MB = 1024KB
      if (!isAllowedType) {
        this.$message.error('最多选择3个文件')
      }
      if (!isSizeValid) {
        this.$message.error('文件大小总共不能超过5MB.')
      }
      return isAllowedType && isSizeValid
    },
    // test 文件上传
    handleChange(file, fileList) {
      this.multiFile = []
      fileList.forEach((el) => {
        console.log(1111)
        this.multiFile.push(el.raw)
      })
      console.log('this.multiFile==>', this.multiFile)
    },

    changePhone_02() {
      document.querySelectorAll('.gridItem').forEach((item) => {
        item.classList.add('active')
      })
      document.querySelectorAll('.tab_item').forEach((item) => {
        item.classList.add('active_tab')
      })
    },
    changePhone() {
      document.querySelectorAll('.gridItem').forEach((item) => {
        item.classList.remove('active')
      })
      document.querySelectorAll('.tab_item').forEach((item) => {
        item.classList.add('active_tab')
      })
    }
  }
}
</script>
<style src="./index.css" scoped></style>
