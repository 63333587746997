<template>
  <div id="app">
    <!-- 头部区域 -->
    <Header></Header>
    <!-- 用于呈现页面中的其他组件 -->
    <router-view></router-view>
    <!-- 底部区域 -->
    <Footer></Footer>
  </div>
</template>
<script>
import Header from './components/Header/headerIndex'
import Footer from './components/Footer/footerIndex'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>
<style lang="less">
body {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  width: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
}
</style>
