<template>
  <div class="container">
    <!-- 视频组件 -->
    <div class="video_box">
      <video
        class="video_01"
        src="https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/video.mp4"
        muted
        loop
        object-fit="fill"
        autoplay
      ></video>
      <div class="textWrapper">
        <div class="video_01_title">数字世界 领航未来</div>
        <div class="icons_box">
          <div class="icon_item" :key="index" v-for="(item, index) in iconList">
            <div class="circle_img">
              <div class="circle_2">
                <img class="circle_img_item" :src="iconList[index].url" alt="" />
              </div>
            </div>
            <div class="icon_item_text">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 公司介绍  -->

    <div class="intro" id="section-1">
      <div class="intro_text">
        <div class="animate"><p>关于我们</p></div>
        <div class="animate"><p>ABOUT US</p></div>
      </div>
      <div class="intro_profile">
        <div class="animate">
          <p>
            苏州快活猿科技有限公司成立于2021年，是从事科技推广和应用服务业为主的企业，拥有多个注册商标、软件著作权、专利信息，秉承高标准的服务品质与“成就客户”的服务理念，服务了众多企业及客户。
          </p>
        </div>
        <div class="animate">
          <p>
            公司以AI算法、大数据分析、物联网平台为核心能力，为智慧社区、智慧园区、智慧商业、智慧市政、互联网等细分场景提供全生态服务，为产业提供丰富的人工智能算法与基建平台，赋能百业完成智能化转型升级。
          </p>
        </div>
      </div>
      <!-- 视差滚动 -->
      <div class="intro_box pic">
        <div class="pictext_word">TECHNOLOGY CREATES THE FUTURE</div>
        <div class="pictext">科技创造未来</div>
      </div>
      <div class="intro_profile">
        <div class="animate">
          <p>
            公司主要业务和能力为系统应用、移动端应用、工业互联网、算法AI、物联网。公司深耕行业多年，形成了具备智力裂变能力的开发者生态，以高效、灵活、优质的服务满足企业需求，与恒力、恒瑞、亨通、迈瑞等单位为长期合作伙伴关系。
          </p>
        </div>
        <div class="animate">
          <p>公司期待通过不断努力，锐意进取，让科技真正创造价值、普惠大众。</p>
        </div>
      </div>
    </div>

    <!-- ----------------------------  移动端的信息技术服务   ------------------------------- -->
    <div class="mobile" id="section-2">
      <div class="banner1_text">
        <div class="animate"><p class="banner1_text_01">信息技术服务</p></div>

        <p class="banner1_text_02">灵活交付与专业管控，助推全流程数字化升级</p>
        <ul class="banner1_meaus_mob">
          <li class="banner1_meaus_item" :key="index" v-for="(item, index) in serviceList" @click="handleServiceItemClick(index)">
            {{ item.tip }}
          </li>
        </ul>
        <div class="banner1_meaus_box_mob">
          <img :src="serviceList[currentIndex].pic" alt="" />
          <div class="banner1_meaus_box_title">{{ serviceList[currentIndex].tip }}</div>
          <p id="banner1_meaus_box_title_tip">{{ serviceList[currentIndex].description }}</p>
          <p id="banner1_meaus_box_mob_content">{{ serviceList[currentIndex].content }}</p>
        </div>
      </div>
    </div>

    <!-- 各行业发展 -->
    <div class="profile" id="section-3">
      <div class="profilecontent">
        <img :src="contentList[contentIndex].bgimg" alt="" style="width: 100%; height: 100%; object-fit: cover" />
        <div class="content_box">
          <div class="content_title">{{ contentList[contentIndex].title }}</div>
          <div class="content_body">
            {{ contentList[contentIndex].content }}
          </div>
        </div>
      </div>

      <nav>
        <div class="nav_title">
          <p>行业解决方案</p>
          <p class="nav_title_plan">行业方案，提升企业核心竞争力</p>
        </div>
        <ul class="menus">
          <li class="li" :key="index" v-for="(item, index) in contentList" @mouseenter="handleChangeTab(index)">
            {{ item.tip }}
          </li>
        </ul>
      </nav>
    </div>
    <!-- 全面平台 -->
    <div class="banner2">
      <div class="banner2_box">
        <div class="animate"><div class="banner2_title">我们的服务</div></div>
        <div class="animate"><p class="banner2_box_english">OUR SERVICES</p></div>

        <div class="serviceContent">
          <img src="https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/Snipaste_2024-03-26_09-18-19.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 我们优势 -->
    <div class="advantage_box" id="section-4">
      <div class="advantage_title">快活猿 · 优势</div>
      <div class="items">
        <div class="item" :key="index" v-for="(item, index) in advantageList">
          <!--   :ref="`item-${index}`"
          @click="handleitemClick(index)"-->
          <img :src="advantageList[index].img" alt="" />
          <p>{{ item.title }}</p>
          <div class="item_text">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../main.js'

export default {
  name: 'homeView',
  data() {
    return {
      activeIndex: null,
      contentIndex: 0,
      currentSectionIndex: null,
      iconList: [
        { title: '云计算', url: require('../../assets/icon/yunjisuan.svg') },
        { title: '物联网', url: require('../../assets/icon/wulianwang.svg') },
        { title: '大数据', url: require('../../assets/icon/dashuju.svg') },
        { title: '人工智能', url: require('../../assets/icon/rengongzhineng.svg') },
        { title: '移动互联网', url: require('../../assets/icon/yidongduan.svg') }
      ],
      contentList: [
        {
          tip: '高科技',
          bgimg: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/ai-generated-8584133_1280.webp',
          title: '高科技',
          content:
            '科技快速发展推动了技术创新和研发投入，加速了人工智能、大数据分析、物联网等前沿技术的应用与普及，快活猿为高科技企业研发出了3D打印控制系统，提供了弹性、可靠的基础设施和数据存储服务，用于控制打印设备的运动，状态查看，材料挤出，固化，推动了高科技企业的创新和发展。'
        },
        {
          tip: '物联网',
          title: '物联网',
          bgimg: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/wordpress-581849_1280.jpg',
          content:
            '物联网行业在全球范围内持续快速发展，不断涌现出新的科技创新和商业模式，建设和维护物联网基础设施、提供各类物联网服务，推动了信息社会的发展。快活猿通过基于GMP的智慧厂房，智慧监狱，为工厂、园区、电网、城市构建数字化模型，通过固定或者移动设备采集数据，为企业管理和城市运营者提供直观的数据大屏看板。解决企业管理中面临的信息分散和数据孤岛问题，提高企业运行效率，提高管理的智能化水平。'
        },

        {
          tip: '游戏文娱',
          title: '游戏文娱',
          bgimg: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pexels-cottonbro-studio-4836510.jpg',
          content:
            '随着社会经济发展与智能电子设备的普及,在物质需求普遍得到满足的情况下，人们对于精神层面的需求越来越大，游戏文娱行业得到空前未有的盛况，如何宣传吸引用户，使用创新手段变现并实现长期发展，也成了迫切需求。如今，快活猿为动漫、游戏、文创等相关厂商提供了强有力的技术及运营管理支持，提供最优技术解决方案，提升用户粘性与产品核心竞争力，助力长期健康发展。'
        },

        {
          tip: '医疗健康',
          title: '医疗健康',
          bgimg: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/analysis-2030266_1280.jpg',
          content:
            '医疗健康行业正处于快速发展和转型的阶段，数字化、智能化、个性化将成为未来医疗健康服务的重要发展方向，助力人们获得更好的医疗服务和健康管理。快活猿通过开发动物行为学分析软件、血管介入手术模拟显示平台等上位机软件为医学实验提供数据的实时采集、监控和分析，提高数据质量和准确性。借助互联网，医疗实验可以进行虚拟试验设计和模拟。研究人员可以利用计算机模拟技术对实验设计进行优化，预测实验结果，节约时间和成本。'
        },
        {
          tip: '能源',
          title: '能源',
          bgimg: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/park-wind-farm-3704939_1280.jpg',
          content:
            '能源行业正在经历数字化转型，包括智能电网、数字化油田等领域的发展。先进的信息技术被应用于能源生产、传输、储存和使用过程中，提高了能源系统的效率和可靠性。'
        }
      ],
      currentIndex: 0,
      serviceList: [
        {
          tip: '互联网软件',
          description: '利用数据驱动变革，创新和快速发展的引擎',
          pic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/cyber-3400789_1280.jpg',
          content:
            '互联网软件行业是指开发、设计和提供基于互联网的各种软件应用程序的产业,可以包括网站、移动应用程序、在线服务等,具有快速迭代、技术驱动和创新性强的特点，通常涉及到前端开发、后端开发、数据库管理、云计算等多个领域。'
        },
        {
          tip: '智慧工厂/城市',
          description: '生产自动化和智能化管理的现代制造模式',
          pic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pexels-essow-k-936722.jpg',
          content:
            '构建数字化模型，通过固定或者移动设备采集数据，为企业管理和城市运营者提供直观的数据大屏看板。解决企业管理中面临的信息分散和数据孤岛问题，提高企业运行效率，提高管理的智能化水平。'
        },
        {
          tip: '上位机软件',
          description: '自动化、工业控制、数据采集、实时监控',
          pic: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/colorhub.me_photos_e7Noe_1920x1080.jpeg',
          content:
            '更高的计算能力和内存资源，可以处理大量数据和复杂的算法，提供直观的用户界面，使用户可以方便地与设备或系统进行交互，为客户远程监控和控制设备或系统，实现远程操作管理。'
        }
      ],
      advantageList: [
        {
          img: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pexels-fauxels-3183150.jpg',
          title: '阿里算法团队',
          content: '团队leader具有阿里达摩院背景，苏州momenta工作经历，多年视觉算法开发经验，在汽车领域智能驾驶领域深入研究'
        },
        {
          img: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/pexels-fauxels-3184635.jpg',
          title: '清华工程师团队',
          content: '团队规模50余人，专门负责大型物联网类项目，经验丰富，技术高超'
        },
        {
          img: 'https://have-fun.oss-cn-hangzhou.aliyuncs.com/website/technology-6701406_1280.jpg',
          title: '上交智能控制团队',
          content: '前身为上海交大团队，团队内有多名资深控制工程师，在物联网工业智能控制多年深耕'
        }
      ]
    }
  },
  created() {
    eventBus.$on('scrollToSection', (id) => {
      const targetElementId = `section-${id}`
      const targetElement = document.getElementById(targetElementId)

      if (targetElement) {
        if (window.innerWidth > 550) {
          const offset = 90 // 设置滚动偏移量
          const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          })
        } else {
          const mobile_offset = 60
          const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - mobile_offset
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          })
        }
      }
    })
    document.addEventListener('item', function () {
      const items = document.querySelectorAll('.item')

      items.forEach((item) => {
        item.addEventListener('click', function () {
          // 先移除所有元素的 active 类名
          items.forEach((i) => i.classList.remove('active'))

          // 给被点击的元素添加 active 类名
          this.classList.add('active')
        })
      })
    })
  },
  mounted() {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // 如果 intersectionRatio 为 0，则目标在视野外，
          // 不需要做任何事情。
          // console.log('entry=>', entry)
          if (entry.isIntersecting) {
            console.log('在视口内')
            entry.target.children[0].classList.add('animateActive')
          } else {
            console.log('不在视口内')
            entry.target.children[0].classList.remove('animateActive')
          }
        })
      },
      { threshold: 0.5 }
    )
    const animateItems = document.querySelectorAll('.animate') //获取所有animate
    animateItems.forEach((animateItem) => {
      intersectionObserver.observe(animateItem)
    })
    // 多行文本的动效
    const intersectionObserver_02 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.children[0].classList.add('animateActive_02')
          } else {
            entry.target.children[0].classList.remove('animateActive_02')
          }
        })
      },
      { threshold: 0.1 }
    )
    const animateItems_02 = document.querySelectorAll('.animate_02') //获取所有animate
    animateItems_02.forEach((animateItem) => {
      intersectionObserver_02.observe(animateItem)
    })
    // 优势动画
  },
  methods: {
    // 行业解决方案
    handleChangeTab(index) {
      this.contentIndex = index
    },
    handleServiceItemClick(index) {
      const temp1 = document.querySelectorAll('.banner1_meaus_item')
      this.currentIndex = index
      temp1.forEach((item, index) => {
        item.classList.remove('active')
      })
      temp1[this.currentIndex].classList.add('active')
    },
    // 点击快活猿优势
    handleitemClick(index) {
      console.log('this.$refs[`item-${index}`]-', this.$refs[`item-${index}`])
      this.$refs[`item-${index}`][0].classList.toggle('active')
      // const div = document.querySelector('.item').classList.toggle('active')
    }
  }
}
</script>

<style src="./index.css" scoped></style>
