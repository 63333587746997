<template>
  <!-- 底部 -->
  <footer class="footer">
    <div class="footer-container">
      <div class="contactInfo">
        <div class="contactWay">
          <p id="contactInfo_title">苏州快活猿科技有限公司</p>
          <div class="bottomInfo">
            <img src="../../assets/icon/dizhi.svg" alt="" style="width: 25px; margin-right: 30px" />
            <div class="info">公司地址：苏州市相城区高铁新城蠡太路芯汇湖商务中心1幢1324室</div>
          </div>
          <div class="bottomInfo">
            <img src="../../assets/icon/dianhua.svg" alt="" style="width: 25px; margin-right: 30px" />
            <div class="info">联系我们：0512-65999885</div>
          </div>
          <div class="bottomInfo">
            <img src="../../assets/icon/yewuzixun.svg" alt="" style="width: 25px; margin-right: 30px" />
            <div class="info">业务咨询：marketing@kuaihuoyuan.cn</div>
          </div>
          <div class="bottomInfo">
            <img src="../../assets/icon/toujianli.svg" alt="" style="width: 33px; margin-right: 21px" />
            <div class="info">投递简历：marketing@kuaihuoyuan.cn</div>
          </div>
        </div>
      </div>
      <div class="footerListbox">
        <div class="footerList">
          <div class="footerItem">
            <div class="footerItem_title" @click="toIndex">快活猿服务</div>
            <div class="footerItemCon">
              <p :key="item.id" v-for="item in homeList" @click="scrollToView(item.id)">
                {{ item.title }}
              </p>
            </div>
          </div>

          <div class="footerItem">
            <div class="footerItem_title" @click="toUs">关于我们</div>
            <div class="footerItemCon">
              <p :key="item.id" v-for="item in usList" @click="scrollToUs(item.id)">
                {{ item.title }}
              </p>
            </div>
          </div>

          <!-- <div class="footerItem">
          <h3>售后服务</h3>
          <ul class="footerItemCon">
            <li>售后政策</li>
            <li>价格保护</li>
          </ul>
        </div> -->
        </div>
      </div>
    </div>
    <!-- 备案号 -->
    <div class="foot_bottom">
      <span> ©2024 Huaweicloud.com 版权所有 苏州快活猿科技有限公司 </span>
      <span>贵公网安备：52990002000093号</span>
      <span>备案号：苏ICP备2023016884号-1</span>
    </div>
  </footer>
</template>

<script>
import { eventBus } from '../../main.js'
export default {
  name: 'FooterView',
  data() {
    return {
      homeList: [
        { title: '了解我们', id: 1 },
        { title: '信息技术服务', id: 2 },
        { title: '行业解决方案', id: 3 },
        { title: '我们的优势', id: 4 }
      ],
      usList: [
        { title: '公司简介', id: 5 },
        { title: '荣誉资质', id: 6 },
        { title: '精彩记忆', id: 7 },
        { title: '联系我们', id: 8 }
      ]
    }
  },
  mounted() {
    // this.scrollToView()
    // this.scrollToUs()
  },
  methods: {
    scrollToView(id) {
      console.log('route--->', this.$route.path)
      if (this.$route.path !== '/home') {
        this.$router.push('/home').then(() => {
          eventBus.$emit('scrollToSection', id)
        })
      } else {
        eventBus.$emit('scrollToSection', id)
      }
    },
    scrollToUs(id) {
      if (this.$route.path !== '/aboutus') {
        this.$router.push('/aboutus').then(() => {
          eventBus.$emit('scrollToSection_02', id)
        })
      } else {
        eventBus.$emit('scrollToSection_02', id)
      }
    },
    toUs() {
      this.$router.push('/aboutus')
    },
    toIndex() {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less" src="./footer.less" scoped></style>
