import Vue from 'vue'
import VueRouter from 'vue-router'
import aboutUs from '../views/aboutUs/index.vue'
import homeView from '../views/homeView/index.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
//解决vue路由重复导航错误
//获取原型对象上的push函数
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: homeView
  },
  {
    path: '/aboutus',
    component: aboutUs
  }
]
const router = new VueRouter({
  routes
})

export default router
